import {useNuxtApp} from "#build/imports";

export interface ISEOTitles {
	seoTitle?: string;
	seoDescription?: string;
	seoKeywords?: string;
	seoImage?:string;
	seoVideo?:string;
}
type SeoHandlersType = {
	setSEOTitles:(options:ISEOTitles)=>void;
}

export function seoHandlers ():SeoHandlersType {
  const { ssrContext } = useNuxtApp();
  const setSEOTitles = ({ seoTitle, seoDescription, seoKeywords, seoVideo, seoImage }: ISEOTitles) => {
    useHead({
      title: seoTitle || '',
      titleTemplate: '%s',
      meta: [
        { name: 'description', content: seoDescription || '' },
        { name: 'keywords', content: seoKeywords || '' }
      ]
    });

    const routePath = useNuxtApp()._route.path;
    const pathsForDisallow = ['/profile', '/profile/info', '/profile/addreses', '/profile/reviews', '/partner-apply-from-mobile'];

    useSeoMeta({
      ogTitle: () => `${seoTitle || ''}`,
      ogDescription: () => `${seoDescription || ''}`,
      ogType: 'website',
      ogLocale: 'ru_RU',
      ogUrl: () => (process.server ? ssrContext!.event.node.req.headers.host : window.location.host),
      ogImage: () => `${seoImage || ''}`,
      ogSiteName: () => 'SakhFood',
      twitterDescription: () => `${seoDescription || ''}`,
      twitterImage: () => `${seoImage || ''}`,
      twitterTitle: () => `${seoTitle || ''}`,
      twitterCard: 'summary_large_image',
      robots: () => (pathsForDisallow.includes(routePath) ? 'noindex, nofollow' : ''),
    });
  };

  return {
    setSEOTitles
  };
}
