<template>
  <div v-if="!token && token !== false" class="gdpr-consent-banner">
    <p>
      Мы используем используем сервис "Яндекс.Метрика", который использует файлы "cookie".
      <NuxtLink to="/privacy-ym"><span class="underline text-firmennyy"> Подробнее здесь</span></NuxtLink>.
    </p>
    <button @click="giveConsent"><span class="text-white">Я согласен / согласна</span></button>
    <button @click="denyConsent"><span class="text-white">Я отказываюсь</span></button>
  </div>
</template>

<script setup>
const token = useCookie('YandexMetric');
const router = useRouter();

const giveConsent = () => {
  token.value = 'true';
  router.go(0)
};

const denyConsent = () => {
  token.value = 'false'
};
</script>

<style scoped>
.gdpr-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
}

button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  background: rgb(80 168 10 / var(--un-bg-opacity));
  border-radius: 0.75rem;
  color: #fff;
  cursor: pointer;
}

button:hover {
  opacity: .8;
}
</style>
