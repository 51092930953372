<template>
  <div
    v-on-click-outside="() => isShow = false"
    class="lg:relative max-lg:peer-[_]:order-5 order-3 flex-1 lg:order-1"
    @keyup.enter="navigateTo(`/search?search=${search}&open_tab=restaurants`)"
  >
    
<FormKitLazyProvider config-file="true" :default-config="false">
<FormKit
      v-model="search"
      :delay="500"
      outer-class="h-full"
      wrapper-class="h-full"
      inner-class="$remove:border-border border-#F4F8F5 $remove:bg-transparent bg-#f4f8f5 $remove:rounded-3 rounded-2.5 max-lg:px-2 h-full"
      input-class="$remove:text-4.25 text-3 lg:text-4.5"
      placeholder="Найти ресторан или блюдо"
      @focus="isShow = true"
    >
      <template #prefixIcon="{ classes, id }">
        <label
          :for="id"
          :class="classes.prefixIcon"
          class="i-custom:search text-firmennyy max-lg:mr-1"
        ></label>
      </template>
    </FormKit>
    <Tabs
      v-if="isShow"
      :ui="{ header: 'flex bg-#f5f8f9 rounded-2.5 text-3.25 text-seryy w-fit mx-1.5' }"
      class="flex flex-col gap-1.5 py-3.5 pr-3 pl-1.5 w-full absolute top-full translate-y-1.5 z-20 bg-white rounded-4 shadow-[0_6px_18px_0] shadow-black/16 ring-1 ring-inset ring-#f1f1f1 lg:min-w-100 max-lg:left-0"
    >
      <Tab
        :title="`Блюда ${allProducts?.length ?? 0}`"
        name="tab1"
      >
        <div class="max-h-78 overflow-auto custom-scroll pr-2">
          <div
            v-if="isLoading"
            class="flex justify-center items-center"
          >
            <i class="i-custom:retry animate-spin"></i>
          </div>
          <p
            v-if=" searchRes && !searchRes?.length"
            class=" text-tekstovyy text-4.5 px-2"
          >
            Ничего не найдено
          </p>
          <button
            v-for="product in allProducts"
            :key="product.id"
            class="flex items-center gap-2.5 pr-1 text-tekstovyy border-y-6 border-x-8 border-transparent hover:text-firmennyy hover:bg-#f5f8f9 hover:border-#f5f8f9 transition-colors text-4.5 -tracking-0.01em rounded-2.5 w-full"
            @click="handleOpenPopup(product.slug)"
          >
            <div itemscope itemtype="http://schema.org/ImageObject" class="overflow-hidden size-10 rounded-1.5 ring-0.5 p-0.5 ring-border/50 ring-inset bg-white shrink-0">
              <NuxtImg
                itemprop="contentUrl"
                class="w-full h-full object-center object-contain"
                :src="product.img_path"
                alt=""
              />
            </div>
            <span class="grow text-left">{{ product.title }}</span>
            <span class="font-500 shrink-0">{{ product.price }} ₽</span>
          </button>
        </div>
        <Button
          :tag="NuxtLink"
          :to="`/search?search=${search}`"
          color="green-light"
          class="mt-1.5 w-fit ml-2"
          :ui="{ padding: 'px-7 py-3', text: 'text-3.5', rounded: 'rounded-3' }"
          @click="isShow = false"
        >
          Все результаты
        </Button>
      </Tab>
      <Tab
        :title="`Рестораны ${searchRes?.length ?? 0}`"
        name="tab2"
      >
        <div class="max-h-78 overflow-auto custom-scroll pr-2">
          <p
            v-if="searchRes && !searchRes?.length"
            class=" text-tekstovyy text-4.5 px-2"
          >
            Ничего не найдено
          </p>
          <div
            v-if="isLoading"
            class="flex justify-center items-center"
          >
            <i class="i-custom:retry animate-spin"></i>
          </div>
          <NuxtLink
            v-for="restaurant in searchRes"
            :key="restaurant.restaurant.id"
            :to="`/restaurants/${restaurant.restaurant.slug}`"
            class="flex items-center gap-3.5 text-tekstovyy border-y-6 border-x-8 border-transparent hover:text-firmennyy hover:bg-#f5f8f9 hover:border-#f5f8f9 transition-colors text-4.5 -tracking-0.01em rounded-2.5"
          >
            <div itemscope itemtype="http://schema.org/ImageObject" class="overflow-hidden size-10 rounded-1.5 ring-0.5 p-0.5 ring-border/50 ring-inset bg-white shrink-0">
              <NuxtImg
                itemprop="contentUrl"
                class="w-full h-full object-center object-contain"
                :src="restaurant.restaurant.img_path"
                alt=""
              />
            </div>
            <div class="flex flex-col gap-2">
              <span class="grow font-700">{{ restaurant.restaurant.title }}</span>
            </div>
          </NuxtLink>
        </div>
        <Button
          v-if="searchRes && searchRes.length"
          :tag="NuxtLink"
          :to="`/search?search=${search}&open_tab=restaurants`"
          color="green-light"
          class="mt-1.5 w-fit ml-2"
          :ui="{ padding: 'px-7 py-3', text: 'text-3.5', rounded: 'rounded-3' }"
          @click="isShow = false"
        >
          Все результаты
        </Button>
      </Tab>
    </Tabs>
</FormKitLazyProvider>

  </div>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { NuxtLink } from '#components';
import { vOnClickOutside } from '@vueuse/components';
import { useSearch } from '~/composables/search/useSearch';
import type { ProductType } from '~/composables/product/types/UseProductType';
import { useProduct } from '~/composables/product/useProduct';
import { useProductPopup } from '~/composables/popups/useProductPopup';
const { openPopup } = useProductPopup();
const { getProductBySlug } = useProduct();

const { performSearch, isLoading } = useSearch();

const search = ref('');
const { data: searchRes, execute, status } = await useAsyncData('search', () => performSearch({ query: search.value }), {
  immediate: false
});

watch(search, (newValue) => {
  if (newValue.length < 2) { return clearNuxtData('search'); }
  execute();
});

async function handleOpenPopup (slug: string) {
  const res = await getProductBySlug(slug)
    .catch((e) => {
      console.log(e._data.message);
      return null;
    });

  if (res) {
    openPopup({
      currentProduct: res
    });
  }
}

const allProducts = computed(() => {
  return searchRes.value?.reduce((all: ProductType[], item) => {
    return all.concat(item.product_list ? item.product_list : []);
  }, []);
});

const isShow = ref(false);
</script>
