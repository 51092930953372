import type { ComputedRef } from 'vue';
import type {
  SecretCodeType,
  UserChangeForgotPasswordReqType,
  UserCheckPhoneReqCode,
  UserLoginRequestType, UserLoginResponseType, UserMeReqType,
  UserMeType,
  UserRegistrationRequestType, UserResetPasswordReqType, UserLoginBySecretCodeType, UserDeleteAccReqType
} from '~/composables/user/types/UserTypes';

import { userStore as store } from '~/stores/user-store';
import { storeToRefs, computed } from '#imports';
import type { ServerResponseCommonType } from '~/types/commons';

type TransportUserType = {
  userRegistration: (options: UserRegistrationRequestType) => Promise<ServerResponseCommonType | null>;
	getUserMe: (options?:UserMeReqType) => Promise<UserMeType | null>;
	isLoggedIn: ComputedRef<boolean>;
	currentUser: ComputedRef<UserMeType | null>;
  userLoginBySecretCode: (body: UserLoginBySecretCodeType) => Promise<UserLoginResponseType | null>;
  userLogin: (options: UserLoginRequestType) => Promise<UserLoginResponseType | null>;
  logOut: () => Promise<ServerResponseCommonType | null>;
  userResendVerificationPhone: (options: {phone: string, 'g-recaptcha-response'?: string}) => Promise<ServerResponseCommonType | null>;
  userForgotPassword: (email: string) => Promise<ServerResponseCommonType | null>;
  userResetPassword: (options: UserResetPasswordReqType) => Promise<ServerResponseCommonType | null>;
  userChangePassword: (password:string) => Promise<ServerResponseCommonType | null>;
	userCheckPhoneCode:(options:UserCheckPhoneReqCode) => Promise<string | null>;
  userChangeForgotPassword: (options: UserChangeForgotPasswordReqType) => Promise<ServerResponseCommonType | null>
  userDeleteAccount:() => Promise<ServerResponseCommonType | null>
}

export function useUserAuth (): TransportUserType {
  const userStore = store();
  const authToken = useCookie('authToken', {
    maxAge: (60 * 60 * 24 * 30 * 12),
    path: '/'
  });
  const { $api } = useNuxtApp();
  const {
    isUserLoggedIn,
    userMe
  } = storeToRefs(userStore);

  const isLoggedIn = computed(() => isUserLoggedIn.value);
  const currentUser = computed(() => userMe.value);

  const getUserMe = async (options?:UserMeReqType): Promise<UserMeType | null> => {
    if ((options === undefined && userMe.value) || (options && !options.isNeedUpdate)) {
      return userMe.value;
    }

    const res = await $api<ServerResponseCommonType<UserMeType>>('user/getPersonalInfo')
      .catch((err) => { throw err; });

    if (res && res.data) {
      userStore.setUser(res.data);
      return res.data;
    }

    return null;
  };

  const userLogin = async (options: UserLoginRequestType): Promise<UserLoginResponseType | null> => {
    const res = await $api<ServerResponseCommonType<UserLoginResponseType>>('user/login', {
      method: 'POST',
      body: { ...options }
    }).catch((err) => { throw err; });

    if (res.message === 'Success') {
	    authToken.value = res.data.token;

      await nextTick();
	    await getUserMe();
      return res.data;
    }

    return null;
  };

  const userRegistration = async (options: UserRegistrationRequestType): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/register', {
      method: 'POST',
      body: { ...options }
    }).catch((err) => { throw err; });

    if (res && res.data) {
	    return res;
    }

    return null;
  };

  const logOut = async (): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/logout', {
      method: 'POST'
    }).catch((err) => { throw err; });

    if (res && res?.message === 'Success') {
	    authToken.value = null;
      userStore.setUser(null);
    }
    return res;
  };

  const userResendVerificationPhone = async (options: {phone: string, 'g-recaptcha-response'?: string}): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/resendVerificationCall', {
      method: 'POST',
      body: { phone: options.phone, 'g-recaptcha-response': options['g-recaptcha-response'] }
    })
      .catch((err) => { throw err; });

    if (res && res?.message === 'Success') {
      return res;
    }

    return null;
  };

  const userForgotPassword = async (email: string): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/forgotPassword/sendEmail', {
      method: 'POST',
      body: { email }
    }).catch((err) => { throw err; });

    if (res && res?.message === 'Success') {
      return res;
    }

    return null;
  };

  const userResetPassword = async (options: UserResetPasswordReqType): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/resetPassword', {
      method: 'POST',
      body: { ...options }
    }).catch((err) => { throw err; });

    if (res && res?.message === 'Success') {
      return res;
    }

    return null;
  };

  const userChangePassword = async (password:string): Promise<ServerResponseCommonType | null> => {
    const res = await $api<ServerResponseCommonType>('user/changePassword', {
      method: 'POST',
	    body: { password }
    }).catch((err) => { throw err; });

    if (res && res?.message === 'Success') {
      return res;
    }

    return null;
  };

  async function userCheckPhoneCode (options:UserCheckPhoneReqCode):Promise<string | null> {
    const res = 	await $api<ServerResponseCommonType<SecretCodeType>>('user/checkVerificationCode', {
      method: 'POST',
      query: { ...options }
    }).catch((err) => { throw err; });

    if (res && res.data) {
      return res.data.secret_code;
    }

    return null;
  }

  async function userChangeForgotPassword (options:UserChangeForgotPasswordReqType) {
    const res = await $api<ServerResponseCommonType>('user/changeForgotPassword', {
      method: 'POST',
      body: { ...options }
    });

    if (res && res?.message === 'Success') {
      return res;
    }

    return null;
  }

  async function userLoginBySecretCode (body:UserLoginBySecretCodeType) {
    const res = await $api<ServerResponseCommonType<UserLoginResponseType>>('/user/loginBySecretCode', {
      method: 'POST',
      body: {
        ...body
      }
    });
    if (res.message === 'Success') {
	    authToken.value = res.data.token;
      await nextTick();
	    await getUserMe();
      return res.data;
    }
    return null;
  }

  async function userDeleteAccount (): Promise<ServerResponseCommonType | null> {
    const res = await $api<ServerResponseCommonType>('user/deleteAccount', {
      method: 'POST'
    });
    if (res && res.message === 'Success') {
      return res;
    }
    return null;
  }

  return {
    userDeleteAccount,
    userLoginBySecretCode,
    isLoggedIn,
    currentUser,
	  userCheckPhoneCode,
    userChangePassword,
    userResetPassword,
    userForgotPassword,
	  userResendVerificationPhone,
    logOut,
    getUserMe,
    userLogin,
    userRegistration,
    userChangeForgotPassword
  };
}
