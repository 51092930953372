import { useUserAuth } from '~/composables/user/useUserAuth';
import { useCart } from '~/composables/cart/useCart';
import { useUserInfo } from '~/composables/user/useUserInfo';

import { useWish } from '~/composables/wish/useWish';

// тут можно загружать и подключать все что нужно инициализировать на стророе клиента при старте
export default defineNuxtPlugin(async (nuxtApp) => {
  const {
    isLoggedIn,
    currentUser,
    getUserMe
  } = useUserAuth();

  const { getCartList } = useCart();
  const { wishGetProductList, wishGetRestaurantList } = useWish();
  const { userAddCurrentAddress } = useUserInfo();

  const user = await getUserMe().catch((err) => {
    console.log('load user plugin:', err.response?._data?.message);
    return null;
  });

  if (!isLoggedIn.value) {}

  await getCartList().catch((err) => {
    console.log('load cart plugin', err.response?._data?.message);
  });

  await wishGetProductList().catch((err) => {
    console.log('load wishGetProductList plugin', err.data.message);
  });

  await wishGetRestaurantList().catch((err) => {
    console.log('load wishGetRestaurantList plugin', err.data.message);
  });

  const address = localStorage.getItem('address');
  if (address && !isLoggedIn.value) { userAddCurrentAddress(JSON.parse(address)); }
  if (currentUser.value?.addresses?.length) {
    userAddCurrentAddress(currentUser.value?.addresses[0]);
  }
});
