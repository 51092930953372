import { default as index30zoQviJMmMeta } from "/home/igorFront/production/sakhfoodfront/pages/about-company/index.vue?macro=true";
import { default as _91slug_934VLwOcaIx4Meta } from "/home/igorFront/production/sakhfoodfront/pages/actions/[slug].vue?macro=true";
import { default as indexj7QpHFNSdfMeta } from "/home/igorFront/production/sakhfoodfront/pages/actions/index.vue?macro=true";
import { default as _91slug_93Hg5uc0uC90Meta } from "/home/igorFront/production/sakhfoodfront/pages/blog/[slug].vue?macro=true";
import { default as indexnkCUdixdhAMeta } from "/home/igorFront/production/sakhfoodfront/pages/blog/index.vue?macro=true";
import { default as _91slug_93WqjkpsMPQzMeta } from "/home/igorFront/production/sakhfoodfront/pages/bluda/[slug].vue?macro=true";
import { default as indexSA2Nr11uQNMeta } from "/home/igorFront/production/sakhfoodfront/pages/bluda/index.vue?macro=true";
import { default as statusy1S0yYo7HxMeta } from "/home/igorFront/production/sakhfoodfront/pages/cabinet/payment/status.vue?macro=true";
import { default as indexGUXKme8bXKMeta } from "/home/igorFront/production/sakhfoodfront/pages/cart/index.vue?macro=true";
import { default as successUyM9KdUQAvMeta } from "/home/igorFront/production/sakhfoodfront/pages/cart/success.vue?macro=true";
import { default as _91slug_93xGdRkrAN7GMeta } from "/home/igorFront/production/sakhfoodfront/pages/categories/[slug].vue?macro=true";
import { default as indexpoVch7J7W7Meta } from "/home/igorFront/production/sakhfoodfront/pages/categories/index.vue?macro=true";
import { default as checking_45partnerseCiUjUC8oCMeta } from "/home/igorFront/production/sakhfoodfront/pages/checking-partners.vue?macro=true";
import { default as contactsdDRdwZmRWSMeta } from "/home/igorFront/production/sakhfoodfront/pages/contacts.vue?macro=true";
import { default as index4SxwYHMmrxMeta } from "/home/igorFront/production/sakhfoodfront/pages/delete-account/index.vue?macro=true";
import { default as details5jtyOCwTekMeta } from "/home/igorFront/production/sakhfoodfront/pages/details.vue?macro=true";
import { default as indexJcOkSihKmnMeta } from "/home/igorFront/production/sakhfoodfront/pages/index.vue?macro=true";
import { default as indexQXz2RlKb3VMeta } from "/home/igorFront/production/sakhfoodfront/pages/info-for-partners/index.vue?macro=true";
import { default as _91slug_93gXYxOoImf7Meta } from "/home/igorFront/production/sakhfoodfront/pages/kuhni/[slug].vue?macro=true";
import { default as indexfQh8CcCLCkMeta } from "/home/igorFront/production/sakhfoodfront/pages/kuhni/index.vue?macro=true";
import { default as offerz2dmX4VzYGMeta } from "/home/igorFront/production/sakhfoodfront/pages/offer.vue?macro=true";
import { default as indexV7hfGHY7w7Meta } from "/home/igorFront/production/sakhfoodfront/pages/partner-apply-from-mobile/index.vue?macro=true";
import { default as privacy_45ymmbIMot3DOwMeta } from "/home/igorFront/production/sakhfoodfront/pages/privacy-ym.vue?macro=true";
import { default as privacyqFleRvqGG2Meta } from "/home/igorFront/production/sakhfoodfront/pages/privacy.vue?macro=true";
import { default as addresesH69fErThfcMeta } from "/home/igorFront/production/sakhfoodfront/pages/profile/addreses.vue?macro=true";
import { default as indexR08WjaTYdrMeta } from "/home/igorFront/production/sakhfoodfront/pages/profile/index.vue?macro=true";
import { default as infoIheiHmx4eZMeta } from "/home/igorFront/production/sakhfoodfront/pages/profile/info.vue?macro=true";
import { default as reviewsRzyHZcuTaRMeta } from "/home/igorFront/production/sakhfoodfront/pages/profile/reviews.vue?macro=true";
import { default as _91slug_93gHZ5LJj5ijMeta } from "/home/igorFront/production/sakhfoodfront/pages/restaurants/[slug].vue?macro=true";
import { default as indexxxDoux4ssGMeta } from "/home/igorFront/production/sakhfoodfront/pages/restaurants/index.vue?macro=true";
import { default as searchixdxWKLmj9Meta } from "/home/igorFront/production/sakhfoodfront/pages/search.vue?macro=true";
import { default as standarts_45partnersQKv4rJfKudMeta } from "/home/igorFront/production/sakhfoodfront/pages/standarts-partners.vue?macro=true";
import { default as terms_45of_45use7UThKleo86Meta } from "/home/igorFront/production/sakhfoodfront/pages/terms-of-use.vue?macro=true";
import { default as indexb9h7tlrzEHMeta } from "/home/igorFront/production/sakhfoodfront/pages/wish/index.vue?macro=true";
export default [
  {
    name: "about-company",
    path: "/about-company",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/about-company/index.vue").then(m => m.default || m)
  },
  {
    name: "actions-slug",
    path: "/actions/:slug()",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/actions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "actions",
    path: "/actions",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/actions/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "bluda-slug",
    path: "/bluda/:slug()",
    meta: _91slug_93WqjkpsMPQzMeta || {},
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/bluda/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bluda",
    path: "/bluda",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/bluda/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-payment-status",
    path: "/cabinet/payment/status",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/cabinet/payment/status.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexGUXKme8bXKMeta || {},
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-success",
    path: "/cart/success",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/cart/success.vue").then(m => m.default || m)
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "checking-partners",
    path: "/checking-partners",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/checking-partners.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "delete-account",
    path: "/delete-account",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/delete-account/index.vue").then(m => m.default || m)
  },
  {
    name: "details",
    path: "/details",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/details.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-for-partners",
    path: "/info-for-partners",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/info-for-partners/index.vue").then(m => m.default || m)
  },
  {
    name: "kuhni-slug",
    path: "/kuhni/:slug()",
    meta: _91slug_93gXYxOoImf7Meta || {},
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/kuhni/[slug].vue").then(m => m.default || m)
  },
  {
    name: "kuhni",
    path: "/kuhni",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/kuhni/index.vue").then(m => m.default || m)
  },
  {
    name: "offer",
    path: "/offer",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/offer.vue").then(m => m.default || m)
  },
  {
    name: "partner-apply-from-mobile",
    path: "/partner-apply-from-mobile",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/partner-apply-from-mobile/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-ym",
    path: "/privacy-ym",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/privacy-ym.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-addreses",
    path: "/profile/addreses",
    meta: addresesH69fErThfcMeta || {},
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/profile/addreses.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexR08WjaTYdrMeta || {},
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-info",
    path: "/profile/info",
    meta: infoIheiHmx4eZMeta || {},
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/profile/info.vue").then(m => m.default || m)
  },
  {
    name: "profile-reviews",
    path: "/profile/reviews",
    meta: reviewsRzyHZcuTaRMeta || {},
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/profile/reviews.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-slug",
    path: "/restaurants/:slug()",
    meta: _91slug_93gHZ5LJj5ijMeta || {},
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/restaurants/[slug].vue").then(m => m.default || m)
  },
  {
    name: "restaurants",
    path: "/restaurants",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/restaurants/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "standarts-partners",
    path: "/standarts-partners",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/standarts-partners.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "wish",
    path: "/wish",
    component: () => import("/home/igorFront/production/sakhfoodfront/pages/wish/index.vue").then(m => m.default || m)
  }
]