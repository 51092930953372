import type { GetOrderType } from '~/composables/order/types/OrderType';
import type { ComputedRef, Ref } from 'vue';

type UserOrderStoreType = {
	userReviewsList:Ref<GetOrderType[] | undefined>;
	setUserReviews:(userReviews:GetOrderType[])=>void;
	orderAmount:ComputedRef<number>;
}

export const userReviewsStore = defineStore('user-orders', ():UserOrderStoreType => {
  const userReviewsList = ref<GetOrderType[] | undefined>(undefined);

  function setUserReviews (userReviews:GetOrderType[]) {
    userReviewsList.value = userReviews;
  }

  const orderAmount = computed(() => {
    if (userReviewsList.value) {
      return userReviewsList.value.length;
    }
    return 0;
  });

  return {
    userReviewsList,
    orderAmount,
    setUserReviews
  };
});
